/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 * - /npm/lazysizes@5.1.2/lazysizes.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/jquery-inview@1.1.2/jquery.inview.min.js
 * - /npm/lazysizes@5.1.2/plugins/unveilhooks/ls.unveilhooks.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
